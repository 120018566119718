import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSearchedArticles } from "../../slices/articles";
import {
  getWebsiteIdFromTxtRecord,
  getWebsiteInfo,
} from "../../slices/websites";
import { generateLighterShade } from "../../utilities/lighterShade";
import Dropdown from "./DropDown";
import logo from "../../assets/img/messaggera_logo.svg";

export default function Header() {
  const { searchedArticles, searchedArticlesStatus } = useSelector(
    (state) => state.articles
  );

  const { websiteID, websiteInfo } = useSelector((state) => state.websites);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [secondColor, setSecondColor] = useState("");

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setDisplay(false);
    } else {
      dispatch(getSearchedArticles({ websiteID, key: e.target.value }));
      setDisplay(true);
    }
  };

  useEffect(() => {
    if (websiteID?.length == 0) {
      dispatch(getWebsiteIdFromTxtRecord());
    }
    if (websiteID && Object.keys(websiteInfo).length === 0) {
      dispatch(getWebsiteInfo(websiteID));
    }

    if (websiteInfo?.color) {
      setSecondColor(generateLighterShade(websiteInfo?.color, 30));
    }
  }, [dispatch, websiteID, websiteInfo]);

  return (
    <header
      style={{
        backgroundImage: websiteInfo?.color
          ? `linear-gradient(125deg, ${websiteInfo?.color} -10%, ${secondColor} 100%)`
          : "linear-gradient(125deg, #E1AE00 -10%, #A37E00 100%)",
      }}
    >
      <div className="header-main">
        <div className="header-main-logo">
          <img src={websiteInfo?.logo ? websiteInfo?.logo : logo} />
          <div className="header-main-logo-text">
            {websiteInfo?.name} | {t("مساعدة")}
          </div>
        </div>
        <div className="header-main-actions">
          <div className="header-main-actions-languages">
            <Dropdown />
          </div>
          <a href={websiteInfo?.url} target="_blank" className="anchor-header">
            <div className="header-main-actions-go">{t("الذهاب للموقع")}</div>
          </a>
        </div>
      </div>
      <div className="header-title">{t("كيف يمكن أن نساعدك ؟")}</div>
      <div className="header-search" style={{ position: "relative" }}>
        <div className="header-search-input">
          <input
            id="search"
            type="text"
            placeholder={t("البحث في مركز المساعدة...")}
            onChange={handleChange}
            value={searchValue}
          />
          {searchedArticlesStatus === "loading" ? (
            <CircularProgress
              style={{
                marginRight: "10px",
                fontSize: "25px",
                fontWeight: "bold",
                width: "16.67px",
                height: "16.67px",
              }}
            />
          ) : (
            <SearchIcon
              style={{
                marginRight: "10px",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            />
          )}
        </div>
        {display && (
          <div className="header-search-results">
            {searchedArticles?.length === 0 ? (
              <div className="header-search-no-results-item">
                <div className="header-search-results-title">
                  {t(
                    "عذرًا، لم يتم العثور على أي نتائج للبحث. جرب البحث بكلمات أخرى"
                  )}
                </div>
              </div>
            ) : (
              searchedArticles?.map((article) => (
                <Link
                  to={`/article/${article.slug}`}
                  state={{ id: article._id }}
                  onClick={() => {
                    setDisplay(false);
                    setSearchValue("");
                  }}
                  key={article._id}
                >
                  <div className="header-search-results-item">
                    <div className="header-search-results-title">
                      {article?.title}
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
        )}
      </div>
    </header>
  );
}
